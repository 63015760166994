import React from 'react';
import { LoadingIndicator } from '@quintype/components';
import './loading-indicator.m.css';

function LoadingIndicatorComponent() {
  return (
    <LoadingIndicator>
      <div className="qt-loading-animation"></div>
    </LoadingIndicator>
  );
}

export { LoadingIndicatorComponent };
