/* eslint-disable max-len */
import get from 'lodash/get';
import find from 'lodash/find';
import take from 'lodash/take';

export function getPrimarySection(sections) {
  if (sections) return sections[0];
  return {};
}

export function getStoryHeaderData({
  'hero-image-s3-key': heroImageS3Key,
  'hero-image-metadata': heroImageMetaData,
  'hero-image-caption': heroImageCaption,
  headline,
  metadata,
  alternative,
}) {
  const storyTemplateAttribute = get(metadata, ['story-attributes', 'storytemplate', '0'], '');
  return {
    headline,
    heroImageData: {
      heroImageS3Key:
        heroImageS3Key ||
        get(alternative, ['home', 'default', 'hero-image', 'hero-image-s3-key'], ''),
      heroImageMetaData:
        heroImageMetaData ||
        get(alternative, ['home', 'default', 'hero-image', 'hero-image-metadata'], {}),
      heroImageCaption:
        heroImageCaption ||
        get(alternative, ['home', 'default', 'hero-image', 'hero-image-caption'], null),
    },
    headlineFallback: get(alternative, ['home', 'default', 'headline']) || headline,
    heroImageFallback: {
      'hero-image-s3-key':
        get(alternative, ['home', 'default', 'hero-image', 'hero-image-s3-key']) || heroImageS3Key,
      'hero-image-metadata':
        get(alternative, ['home', 'default', 'hero-image', 'hero-image-metadata']) ||
        heroImageMetaData,
    },
    storyTemplateAttribute,
  };
}

export function hasSection(sections, sectionName) {
  return find(sections, (section) => section.name === sectionName);
}

export function isStoryExclusive(story) {
  return get(story, ['access-level-value']) === 999 || false;
}

export function getSyndicatedSource(story) {
  return get(story, ['metadata', 'story-attributes', 'syndicatedfrom', '0']);
}

export function getSourceDisplayName(source) {
  if (source === 'thequint') {
    return 'TheQuint';
  } else if (source === 'bloomberg') {
    return 'Bloomberg';
  } else if (source === 'vidible') {
    return 'Vidible';
  }
  return 'BQ';
}

function heroImageFallback(story) {
  const externalHeroImage = get(story, ['metadata', 'external-hero-image']);
  const alternativeHeroImage = get(story, ['alternative', 'home', 'default', 'hero-image']);
  const heroImageKey = get(story, ['hero-image-s3-key']);
  if (externalHeroImage && externalHeroImage.url) {
    return externalHeroImage.url;
  }
  if (alternativeHeroImage) {
    return alternativeHeroImage;
  }
  if (heroImageKey && heroImageKey !== '') {
    return story;
  }
  return false;
}

function getThinmintCookie() {
  return document.cookie.split('; ').filter((item) => item.startsWith('thinmint='));
}

function getThinmintId() {
  const thinmintCookie = getThinmintCookie();
  return thinmintCookie.length && thinmintCookie[0].split('=')[1]
    ? thinmintCookie[0].split('=')[1]
    : '0';
}

function formatStoriesObject(stories) {
  return stories.map((story) => ({
    id: story.Cid,
    slug: story.Slug,
    headline: story.Description,
    metadata: story.ImageMetaData && JSON.parse(story.ImageMetaData),
    publishedAt: story.first_published_at,
    imgUrl: story.ImageUrl,
  }));
}

function getTextElementsOfCards(cards) {
  if (cards) {
    return cards.reduce((acc, currentCard) => {
      return acc.concat(currentCard['story-elements'].filter((element) => element.type === 'text'));
    }, []);
  }
}

export function getPlainText(text = '') {
  return text.replace(/<[^>]+>/g, '');
}

export function getCompleteText(story) {
  const textArray = [];
  if (story?.cards) {
    getTextElementsOfCards(story?.cards).forEach((item) => {
      const textContent = getPlainText(item.text);
      textArray.push(textContent);
    });
    const completeCardText = textArray.join('.');
    return JSON.stringify(completeCardText.replace(/\\"/g, "'").replace(/"/g, "'"));
  }
}

export function getCardText(card) {
  const textArray = [];
  getTextElementsOfCards([card]).forEach((item) => {
    const textContent = getPlainText(item.text);
    textArray.push(textContent);
  });
  const completeCardText = textArray.join('.');
  return completeCardText;
  // return JSON.stringify(completeCardText.replace(/\\"/g, "'").replace(/"/g, "'"));
}

export function getCompleteTextWithoutStringParsing(story) {
  const textArray = [];
  if (story?.cards) {
    getTextElementsOfCards(story?.cards).forEach((item) => {
      const textContent = getPlainText(item.text);
      textArray.push(textContent);
    });
    const completeCardText = textArray.join('.');
    return completeCardText;
  }
}

export const getPreviewContentText = (cards = []) => {
  const storyCards = take(cards);

  const partialStory = storyCards.map((card) => {
    const storyCard = Object.assign({}, card);
    const storyElements = take(storyCard['story-elements']);
    if (storyElements[0].type === 'text') {
      storyElements[0] = Object.assign({}, storyElements[0]);
    }

    storyCard['story-elements'] = storyElements;

    return storyCard;
  });
  return getCompleteText({ cards: partialStory });
};

const getPreviewContentTextWithoutStringParsing = (cards = []) => {
  const storyCards = take(cards);

  const partialStory = storyCards.map((card) => {
    const storyCard = Object.assign({}, card);
    const storyElements = take(storyCard['story-elements']);
    if (storyElements[0].type === 'text') {
      storyElements[0] = Object.assign({}, storyElements[0]);
    }

    storyCard['story-elements'] = storyElements;

    return storyCard;
  });
  return getCompleteTextWithoutStringParsing({ cards: partialStory });
};

export const getPaywallContentText = (cards = []) => {
  const textArray = [];
  if (cards) {
    getTextElementsOfCards(cards).forEach((item, i) => {
      if (i !== 0) {
        const textContent = getPlainText(item.text);
        textArray.push(textContent);
      }
    });
    const completeCardText = textArray.join('.');
    return completeCardText;
  }
};

export const getContentText = (cards = []) => {
  const textArray = [];
  if (cards) {
    getTextElementsOfCards(cards).forEach((item, i) => {
      const textContent = getPlainText(item.text);
      textArray.push(textContent);
    });
    const completeCardText = textArray.join('.');
    return completeCardText;
  }
};

export const TruncatedText = ({ text, maxLength }) => {
  const truncated = text?.length > maxLength ? text?.slice(0, maxLength) + '...' : text;
  return truncated
};
