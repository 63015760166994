import React, { useState, useEffect, useMemo } from 'react';
import { string, bool, objectOf, any, func, array, number, oneOfType } from 'prop-types';
import get from 'lodash/get';
import { connect } from 'react-redux';
import { withError } from '@quintype/components';
import { AdSlot, DFPManager } from 'react-dfp';

const targeting = (state) => {
  const params = {
    environment: get(state.qt, ['config', 'publisher', 'environment']) || state.qt.config.env,
    adfree: global.adfree,
    referralsource: global.referralsource,
    type: get(state, ['qt', 'pageType'], null),
  };
  const pageType = params.type;
  if (pageType === 'home-page') {
    params.section = 'home';
  } else if (pageType === 'story-page') {
    params.type = 'story';
    params.section = get(state, ['qt', 'data', 'story', 'sections', '0', 'name']);
    if (get(state, ['qt', 'data', 'story', 'metadata', 'sponsored-by'])) {
      params.sponsor = get(state, ['qt', 'data', 'story', 'metadata', 'sponsored-by']);
    }
  } else if (pageType === 'section-page') {
    params.type = 'section';
    params.section = get(state, ['qt', 'data', 'section', 'name'], '');
  }

  params.path = get(state, ['qt', 'currentPath']);

  return params;
};

const DfpAd = ({
  defaultNetworkID,
  config,
  targetingArguments,
  adtype,
  slotId,
  collectionName,
  disableDfp,
  collapseEmptyDivs,
  onSlotIsViewable,
  onSlotRender,
  isSubscriber,
  adUnit,
  sizes,
  pageType,
  promo_key,
  promo_replace_key,
}) => {
  const [delayRender, setDelayRender] = useState(false);
  const [isDFPManagerLoaded, setIsDFPManagerLoaded] = useState(false);
  const [dynamicAdUnitName, setDynamicAdUnitName] = useState('');

  useEffect(() => {
    const isBrandStudio = window.location.pathname.substring(1).split('/')[0] === 'bq-brand-studio';
    if (!disableDfp && !isSubscriber && !isBrandStudio) {
      DFPManager.setCollapseEmptyDivs(collapseEmptyDivs);
      DFPManager.load([slotId || adtype]);

      const getSection = /^\/([a-zA-Z0-9-_]+)/.exec(targetingArguments.path);
      const section = getSection ? getSection[1] : null;

      if (typeof window !== 'undefined') {
        window.googletag = window.googletag || { cmd: [] };
        window.googletag.cmd.push(() => {
          if (section) {
            if (window.googletag.pubads && typeof window.googletag.pubads === 'function') {
              window.googletag.pubads().setTargeting('section', section);
            }
          }
        });
      }
    }

    setIsDFPManagerLoaded(true);

    const TIMER_DELAY = 2000;
    const timeout = setTimeout(() => {
      setDelayRender(true);
    }, TIMER_DELAY);
    return () => clearTimeout(timeout);
  }, [disableDfp, slotId, adtype, targetingArguments, collapseEmptyDivs]);

  useEffect(() => {
    if (typeof window === 'undefined') return;
    let formattedCategory;
    let testPage = false;
    const deviceType = window.innerWidth <= 768 ? 'WAP' : 'Desktop';
    const category = window.location.pathname.substring(1).split('/')[0];
    testPage = category.includes('lite');
    const queryParams = new URLSearchParams(window.location?.search);
    const isPromo = queryParams?.get('utm_campaign');
    let utmCampaign = queryParams?.get('utm_campaign');
    if (utmCampaign) {
      utmCampaign = utmCampaign.toLocaleLowerCase();
    }

    if (utmCampaign) {
      // Replace conditions for the campaign
      if (utmCampaign === promo_key[0]) {
        utmCampaign = promo_replace_key[0];
      } else if (utmCampaign === promo_key[1]) {
        utmCampaign = promo_replace_key[1];
      } else if (utmCampaign === promo_key[2]) {
        utmCampaign = promo_replace_key[2];
      } else if (utmCampaign === promo_key[3]) {
        utmCampaign = promo_replace_key[3];
      } else {
        utmCampaign;
      }
    }

    // Check if adUnit is either 'LHS_120x600' or 'RHS_120x600' or 'Sticky_320x75' or 'Interstitial_1x1'
    if (
      adUnit === 'LHS_120x600' ||
      adUnit === 'RHS_120x600' ||
      adUnit === 'Sticky_320x75' ||
      adUnit === 'Interstitial_1x1'
    ) {
      // Set formattedCategory as 'homepage' if on the homepage, else follow the conditions
      if (window.location.pathname === '/') {
        formattedCategory = 'homepage';
      } else if (category === 'ad') {
        formattedCategory = utmCampaign === 'news1' ? 'aff' : utmCampaign;
      } else if (category === 'lite') {
        formattedCategory = window?.location?.pathname === "/lite/video-page-test" ? 'aff_video' : '';
      } else {
        formattedCategory = category.replace(/[-\s]/g, '');
      }
    } else if (pageType && pageType !== 'NA') {
      // General conditions for other adUnit values and pageType checks
      if (pageType === 'home-page') {
        formattedCategory = 'homepage';
      } else if (category === 'ad') {
        formattedCategory = utmCampaign === 'news1' ? 'aff' : utmCampaign;
      } else if (category === 'lite') {
        formattedCategory = pageType === "video-page-test" ? 'aff_video' : '';
      } else {
        formattedCategory = category.replace(/[-\s]/g, '');
      }
    }

    const isHomePage =
      window?.location?.pathname === '/' ||
      window?.location?.pathname === '/lite' ||
      promo_key?.includes(isPromo);

    const isDetailPage = pageType === 'story-page' || pageType === 'story-page-test' || pageType === 'video-page-test';
    const pageSection = isHomePage ? 'Home' : isDetailPage ? 'Detail' : 'Listing';
    const litePrefix = category === 'lite' && window?.location?.pathname !== "/lite/video-page-test" ? 'Test/' : '';

    const name = `${litePrefix}Profit_Eng/${deviceType}/${pageSection}${formattedCategory ? '/' + formattedCategory : ''}`;

    setDynamicAdUnitName(name);
  }, [pageType, adUnit]);

  let updatedAdUnit;
  if (dynamicAdUnitName) {
    updatedAdUnit = `${dynamicAdUnitName}/${adUnit}`;
  }

  const adConfig = useMemo(() => {
    return config[adtype] || {};
  }, [adtype, config]);

  const [showAd, setShowAd] = useState(false);
  useEffect(() => {
    if (typeof window !== 'undefined') {
      const renderAd = window.location.pathname.substring(1).split('/')[0] !== 'bq-brand-studio';
      setShowAd(renderAd);
    }
  }, []);

  return !disableDfp && showAd && isDFPManagerLoaded && delayRender ? (
    <AdSlot
      dfpNetworkId={`/${defaultNetworkID}`}
      targetingArguments={{ ...targetingArguments, collectionName }}
      adUnit={updatedAdUnit}
      sizes={sizes}
      sizeMapping={adConfig.viewPortSizeMapping}
      slotId={slotId || adtype}
      onSlotIsViewable={onSlotIsViewable}
      onSlotRender={onSlotRender}
    />
  ) : null;
};

DfpAd.propTypes = {
  defaultNetworkID: string.isRequired,
  config: objectOf(any).isRequired,
  targetingArguments: objectOf(any).isRequired,
  adtype: string,
  slotId: string,
  adUnit: oneOfType([string, number]),
  sizes: array,
  pageType: string,
  collapseEmptyDivs: bool,
  disableDfp: bool,
  collectionName: string,
  onSlotIsViewable: func,
  onSlotRender: func,
  isSubscriber: bool,
};

DfpAd.defaultProps = {
  slotId: null,
  collapseEmptyDivs: true,
  collectionName: '',
  onSlotIsViewable: () => { },
  onSlotRender: () => { },
};

const mapStateToProps = (state) => ({
  defaultNetworkID: get(state, ['qt', 'config', 'ads', 'network-id'], ''),
  config: get(state, ['qt', 'config', 'ads', 'slots'], ''),
  disableDfp: get(state, ['qt', 'config', 'features', 'disable_dfp', 'should_disable'], false),
  targetingArguments: targeting(state),
  isSubscriber: get(state, ['userData', 'isSubscriber'], false),
  pageType: get(state, ['qt', 'pageType'], 'NA'),
  promo_key: get(state, ['qt', 'config', 'features', 'promo_key'], []),
  promo_replace_key: get(state, ['qt', 'config', 'features', 'promo_replace_key'], []),
});

export default connect(mapStateToProps, () => ({}))(withError(DfpAd));
