import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import get from 'lodash/get';

const ValidateAccess = ({
  environment,
  pageType,
  currentPath,
  isSubscriber,
  promoKey,
  promoReplaceKey,
}) => {
  const [dynamicAdUnitName, setDynamicAdUnitName] = useState('');
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const [showAd, setShowAd] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    const pathname = window.location.pathname.substring(1).split('/')[0];
    const renderAd = pathname !== 'bq-brand-studio' && pageType !== "video-page-test";
    setShowAd(renderAd);

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    if (!showAd) return;

    const deviceType = isMobile ? 'WAP' : 'Desktop';
    const pathname = window.location.pathname.substring(1);
    let category = pathname.split('/')[0]?.replace(/[-\s]/g, '') || 'homepage';

    const queryParams = new URLSearchParams(window.location.search);
    let utmCampaign = queryParams.get('utm_campaign')?.toLocaleLowerCase();
    const isPromo = promoKey.includes(utmCampaign);

    // Replace UTM campaign keys
    if (utmCampaign) {
      const promoIndex = promoKey.indexOf(utmCampaign);
      utmCampaign = promoIndex >= 0 ? promoReplaceKey[promoIndex] : 'aff';
    }

    if (category === 'ad') {
      category = utmCampaign;
    }

    const formattedCategory = pageType === 'home-page' ? 'homepage' : category;
    const formattedPageType = isPromo
      ? 'Home'
      : ['home-page', 'home-page-test'].includes(pageType)
        ? 'Home'
        : ['story-page', 'story-page-test'].includes(pageType)
          ? 'Detail'
          : 'Listing';

    const baseAdUnitPath = window.location.href.includes('/lite')
      ? `1068322/Test/Profit_Eng/${deviceType}/${formattedPageType}`
      : `/1068322/Profit_Eng/${deviceType}/${formattedPageType}/${formattedCategory}`;

    setDynamicAdUnitName(`${baseAdUnitPath}/Interstitial_320x480`);
  }, [pageType, showAd]);

  useEffect(() => {
    const googletag = window.googletag || { cmd: [] };
    googletag.cmd.push(() => {
      if (
        isMobile &&
        dynamicAdUnitName &&
        isSubscriber !== null &&
        !isSubscriber &&
        !window.location.href.includes('aff')
      ) {
        var interstitialSlot320x480 = googletag.defineOutOfPageSlot(
          dynamicAdUnitName,
          googletag.enums.OutOfPageFormat.INTERSTITIAL,
        );
        if (interstitialSlot320x480) {
          interstitialSlot320x480.addService(googletag.pubads());
          googletag.enableServices();
          googletag.display(interstitialSlot320x480.getSlotElementId());
        }
      }

      googletag.pubads().addEventListener('slotRenderEnded', function (event) {
        if (event.slot === interstitialSlot320x480) {
          console.log('Interstitial_320x480 is loaded.');
        }
      });
    });
  }, [isMobile, pageType, isSubscriber, dynamicAdUnitName]);

  return null;
};

ValidateAccess.propTypes = {
  environment: PropTypes.string,
  pageType: PropTypes.string.isRequired,
  currentPath: PropTypes.string.isRequired,
  isSubscriber: PropTypes.bool,
  promoKey: PropTypes.array.isRequired,
  promoReplaceKey: PropTypes.array.isRequired,
};

const DisplayAds = ({ pageType, currentPath, isSubscriber, promoKey, promoReplaceKey }) => (
  <ValidateAccess
    pageType={pageType}
    currentPath={currentPath}
    isSubscriber={isSubscriber}
    promoKey={promoKey}
    promoReplaceKey={promoReplaceKey}
  />
);

DisplayAds.propTypes = {
  pageType: PropTypes.string.isRequired,
  currentPath: PropTypes.string.isRequired,
  isSubscriber: PropTypes.bool.isRequired,
  promoKey: PropTypes.array.isRequired,
  promoReplaceKey: PropTypes.array.isRequired,
};

const mapStateToProps = (state) => {
  const publisherConfig = state.qt.config.publisher;
  return {
    currentPath: state.qt.currentPath || '',
    environment: get(publisherConfig, ['ads', 'environment'], 'production'),
    pageType: get(state, ['qt', 'pageType'], 'home-page'),
    isSubscriber: get(state, ['userData', 'isSubscriber'], false),
    promoKey: get(state, ['qt', 'config', 'features', 'promo_key'], []),
    promoReplaceKey: get(state, ['qt', 'config', 'features', 'promo_replace_key'], []),
  };
};

export default connect(mapStateToProps, {})(DisplayAds);
