import wretch from 'wretch';

export function postCall(url, data, headers = {}) {
  return wretch()
    .options({ credentials: 'same-origin', headers })
    .url(url)
    .post(data);
}

function patchCall(url, data, headers = {}) {
  return wretch()
    .options({ credentials: 'same-origin', headers })
    .url(url)
    .patch(data);
}
