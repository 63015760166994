import React from 'react';
import { func, node, oneOf, string } from 'prop-types';
import './button.m.css';

const Button = ({ variant, size, rounded, label, children, icon, className, ...props }) => {
  return (
    <button
      type="button"
      styleName={`btn btn-${size} btn-${variant} btn-rounded-${rounded}`}
      className={className}
      {...props}
    >
      {icon === 'left' && children}
      {label}
      {icon === 'right' && children}
    </button>
  );
};

Button.propTypes = {
  variant: string,
  size: oneOf(['small', 'medium', 'large']),
  rounded: oneOf(['default', 'full', 'half']),
  label: string,
  onClick: func,
  children: node,
  icon: string,
  styleName: string,
  className: string
};

Button.defaultProps = {
  variant: 'primary',
  size: 'medium',
  rounded: 'default',
  onClick: undefined,
  children: null,
  icon: 'right'
};

export default Button;
