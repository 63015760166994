/* eslint-disable prefer-const */
import get from 'lodash/get';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';

import { BQLink } from '../../../atoms/bqlink';
import MobileSearchBar from '../../../atoms/mobile-search-bar';
import SvgIcon from '../../../atoms/svg-icon';
import MemberMenu from '../../../molecules/member-menu/member-menu.js';
import './header-top.m.css';
import { PATH } from '../../../../constants.js';
import { subscribeClick, watchLiveInteraction } from '../../../../helpers/datalayer-utils.js';
import { isMobile } from 'react-device-detect';
import { getUserStatus } from '../../../../helpers/utils/index.js';

const HeaderTop = (props) => {
  const isSubscriber = useSelector((state) => get(state, ['userData', 'isSubscriber'], null));
  const client_app_version = useSelector((state) =>
    get(state, ['qt', 'config', 'publisher', 'client_app_version'], null)
  );
  const userData = useSelector((state) => get(state, ['userData']));
  const pageType = useSelector((state) => get(state, ['qt', 'pageType']));
  const formattedPageType =
    pageType === 'story-page' ? 'story' :
      pageType === 'home-page' ? 'home-page' :
        'section-page';

  const independence = props?.config.features.independence_day;
  const clearAllCaches = async () => {
    const allCaches = await caches.keys();
    for (const cache of allCaches) {
      caches.delete(cache);
    }
  };

  useEffect(() => {
    if (typeof window !== 'undefined' && client_app_version) {
      const local_version = localStorage.getItem('client_app_version');
      if (local_version == null) {
        localStorage.setItem('client_app_version', client_app_version);
      } else {
        if (local_version !== client_app_version) {
          sessionStorage.clear();
          localStorage.clear();
          indexedDB.databases().then((dbs) => {
            dbs.forEach((db) => indexedDB.deleteDatabase(db.name));
          });
          if ('serviceWorker' in navigator) {
            navigator.serviceWorker.getRegistrations().then(function (registrations) {
              for (let registration of registrations) {
                registration.unregister();
              }
            });
          }
          clearAllCaches();
          window.location.reload(true);
          localStorage.setItem('client_app_version', client_app_version);
        }
      }
    }
  }, []);

  const handleClick = (type, language = null) => {
    const baseData = {
      event: type === 'logo' ? 'logo_click' : 'language_selection',
      page_template: formattedPageType || 'NA',
      user_id: userData?.userProfile?.userId || 'NA',
      user_status: getUserStatus(),
      login_type: userData?.isLoggedIn ? 'logged-in' : 'logged-out',
      language_hit: 'english',
      Platform: isMobile ? 'mobile' : 'desktop',
      SiteName: 'https://www.ndtvprofit.com',
      ...(type === 'language' && {
        select_type: "header",
        language_type: language,
      }),
    };

    if (typeof window !== 'undefined' && window.dataLayer) {
      window.dataLayer.push(baseData);
    } else {
      console.error('Error: window.dataLayer is not available');
    }
  };

  return (
    <div styleName="header-top-container">
      <div styleName="header-top" className="container">
        <div styleName="mobile-search-with-language-toggle">
          <MobileSearchBar />
        </div>
        <div styleName="language-toggle" className="desktop-only">
          <div styleName="toggle-btn-container">
            <button
              styleName="toggle-btn toggle-selected"
              onClick={() => handleClick('language', 'english')}
            >
              English
            </button>
            <BQLink
              href="https://hindi.ndtvprofit.com?utm_source=profit&utm_medium=referral&utm_campaign=language"
              target="_blank"
              rel="noreferrer"
            >
              <button
                styleName="toggle-btn"
                onClick={() => handleClick('language', 'hindi')}
              >
                Hindi
              </button>
            </BQLink>
          </div>
        </div>
        <div onClick={() => handleClick('logo')}>
          <BQLink href="/?src=header" className="mobile-only mob-view">
            <SvgIcon
              type="ndtv_group_logo.webp"
              width="158px"
              height="27px"
              source="image"
              isSvg={false}
            />
          </BQLink>
          <BQLink href="/?src=header" className="desktop-only">
            <SvgIcon
              type="ndtv_group_logo.webp"
              width="173px"
              height="29px"
              source="image"
              isSvg={false}
            />
          </BQLink>
        </div>
        <div styleName="right-ctx">
          {isSubscriber === null ? (
            <div styleName="btn-loading-grey" className="desktop-only" />
          ) : (
            !isSubscriber && (
              <>
                <div onClick={() => subscribeClick(userData, pageType, 'header')}>
                  <div styleName="subscribe-button-wrapper" className="desktop-only">
                    {independence?.active ? (
                      <BQLink href={`${PATH.SUBSCRIPTION_PAGE}?src=subs_hdr`}>
                        <img src={independence.subscription_btn.shadow} styleName="btn-shadow" />
                        <div styleName="button btn-theme">Subscribe</div>
                      </BQLink>
                    ) : (
                      <BQLink href={`${PATH.SUBSCRIPTION_PAGE}?src=subs_hdr`} styleName="button">
                        Subscribe
                      </BQLink>
                    )}
                  </div>
                </div>
              </>
            )
          )}

          <div styleName="user-with-subscribe-btn">
            <MemberMenu {...props} />
          </div>
          <div
            styleName="live-tv-icon"
            className="mobile-only"
            onClick={() => watchLiveInteraction(userData, pageType, 'header')}
          >
            <BQLink href="/live-tv?src=topnav_livetv" styleName="live-icon" title="Live TV">
              <SvgIcon
                type="live_tv"
                iconStyle={{ width: '32px', height: '27px' }}
                source="image"
              />
            </BQLink>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeaderTop;
