import React from 'react';
import { node, object } from 'prop-types';
import { BQLink } from '../bqlink';
import { getAuthor } from '../../../helpers/utils';

import './list-author.m.css';

const ListAuthor = ({ children, story }) => {
  const authorObj = story !== null ? getAuthor(story) : false;

  if (authorObj) {
    return (
      <BQLink href={authorObj.authorSlug} styleName="author" className="author">
        {children}
      </BQLink>
    );
  } else {
    return (
      <div styleName="author" className="author">
        {children}
      </div>
    );
  }
};

ListAuthor.propTypes = {
  children: node.isRequired,
  story: object
};

export default ListAuthor;
