import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { object, array, bool, string, number } from 'prop-types';
import get from 'lodash/get';
import cx from 'classnames';
import { ResponsiveImage } from '@quintype/components';
import PlayIcon from '../../atoms/play-icon';
import ListAuthor from '../list-author';

import { getAuthor, getHeadline } from '../../../helpers/utils';

import './cardImage.m.css';

const CardImageComponent = ({
  story,
  aspectRatio,
  defaultWidth,
  imageType,
  widths,
  eager,
  showHeadline,
  showAuthor,
  imgFallbackS3Key,
  imageRounded,
  isBottomBqBlue,
  isPlayIcon,
  showHeroDetails,
  isLazy,
  isBlur,
  isExclusive,
  fetchPriority,
}) => {
  const [perfObj, setPerfObj] = useState('5vw');
  const wrapperStyleName = cx(
    'qt-image-dynamic',
    { image16x9: imageType === 'image16x9' },
    { image4x3: imageType === 'image4x3' },
    { image1x1: imageType === 'image1x1' },
    { image2x1: imageType === 'image2x1' },
    { image3x4: imageType === 'image3x4' },
    { image3x2: imageType === 'image3x2' },
    { image2x3: imageType === 'image2x3' },
    { image5x4: imageType === 'image5x4' },
    { image6x4: imageType === 'image6x4' },
    { image5x2: imageType === 'image5x2' },
    { image5x3: imageType === 'image5x3' },
    { image3x1: imageType === 'image3x1' },
    { image6x5: imageType === 'image6x5' },
  );
  const alternateImage = get(
    story,
    ['alternative', 'home', 'default', 'hero-image', 'hero-image-s3-key'],
    null,
  );

  let heroImage = alternateImage;
  let headline = getHeadline(story);
  let showHeroImage = true;

  if (!heroImage) {
    heroImage = story['hero-image-s3-key'];
  }

  if (!heroImage) {
    heroImage = story.imgUrl || imgFallbackS3Key;
  }

  if (showHeroDetails) {
    heroImage = story['hero-image-s3-key'] || heroImage;
    headline = story.headline || headline;

    if (!(story['hero-image-s3-key'] || alternateImage)) {
      showHeroImage = false;
    }
  }

  useEffect(() => {
    setPerfObj('(max-width: 600px) 48vw, (max-width: 990px) 52vw, 28vw');
  });

  const showContent = (showAuthor) => (
    <div styleName="content-wrapper" className="content-wrapper">
      <div styleName="content" className="content">
        <h2 styleName="text" className="text-regular story-title">
          {headline}
        </h2>
        {showAuthor && showAuthorView()}
      </div>
    </div>
  );

  const showAuthorView = () => <ListAuthor>{getAuthor(story).name}</ListAuthor>;

  const getBqBlueExclusiveBottomView = () => (
    <div styleName="bottom-bq-blue">
      <span styleName="bq-blue">NDTV Profit</span> <span styleName="exlusive">Exclusive</span>
    </div>
  );

  const getPlayIconView = () => (
    <span styleName="play-icon">
      <PlayIcon width="19" height="18" iconColor="second-color" />
    </span>
  );

  const getPlayIconShadow = () => <img src="/images/rectangle.png" styleName="icon-shadow" />;

  return (
    <div styleName="card-image-wrapper" className="card-image">
      {showHeroImage ? (
        <figure styleName={wrapperStyleName} className="qt-figure">
          <picture className="qt-image">
            <ResponsiveImage
              slug={heroImage}
              metadata={story['hero-image-metadata']}
              aspectRatio={aspectRatio}
              defaultWidth={defaultWidth}
              widths={widths}
              type="image/avif"
              sizes={perfObj}
              imgParams={{ auto: ['format', 'compress'], fmt: 'avif' }}
              styleName={`image img-rounded-${imageRounded}`}
              className="image"
              alt={story['hero-image-caption'] || story.headline || 'NDTV Profit'}
              eager={eager ? 'eager' : isLazy ? 'lazy' : undefined}
              fetchPriority={fetchPriority || undefined}
            />
          </picture>
        </figure>
      ) : (
        <></>
      )}

      {showHeadline && showContent(showAuthor)}
      {isBottomBqBlue && getBqBlueExclusiveBottomView()}
      {isExclusive && (
        <img
          src="/icons/premium_star_bg.svg"
          styleName="exclusive-wrap"
          className="exclusive-wrap"
        />
      )}
      {isPlayIcon && getPlayIconView()}
      {isPlayIcon && getPlayIconShadow()}
    </div>
  );
};

CardImageComponent.defaultProps = {
  story: {},
  aspectRatio: [4, 3],
  imageType: 'image4x3',
  widths: [250, 480, 764],
  eager: false,
  showHeadline: false,
  showAuthor: false,
  imgFallbackS3Key: '',
  imageRounded: 'full',
  isBottomBqBlue: false,
  isPlayIcon: false,
  defaultWidth: 480,
  showHeroDetails: false,
  isLazy: true,
  isBlur: true,
  isExclusive: false,
};

CardImageComponent.propTypes = {
  story: object,
  aspectRatio: array,
  defaultWidth: number,
  imageType: string,
  widths: array,
  eager: bool,
  showHeadline: bool,
  isBottomBqBlue: bool,
  showAuthor: bool,
  isPlayIcon: bool,
  imgFallbackS3Key: string,
  imageRounded: string,
  showHeroDetails: bool,
  isLazy: bool,
  isBlur: bool,
  isExclusive: bool,
};

const mapStateToProps = (state) => ({
  imgFallbackS3Key: get(
    state,
    ['qt', 'config', 'publisher', 'default_image_s3_key'],
    'https://media.assettype.com/bloombergquint/import/ndtv_profit_placeholder___english.png?w=50',
  ),
});

const CardImage = connect(mapStateToProps, null)(CardImageComponent);

export default CardImage;
