import React from 'react';
import { func, node, oneOf, string } from 'prop-types';
import get from 'lodash/get';
import { connect } from 'react-redux';
import './shine-button.m.css';

const ShineButton = ({
  size,
  rounded,
  variant,
  label,
  children,
  icon,
  className,
  features,
  discountedAmnt,
  ...props
}) => {
  const independenceSale = features?.independence_day?.active;
  const labelClass = independenceSale ? 'label' : '';
  return (
    <button
      type="button"
      styleName={`btn shine btn-${size} btn-rounded-${rounded} btn-${variant}`}
      className={`${className}`}
      {...props}
    >
      {icon === 'left' && children}
      <span className={labelClass}>{label}</span>
      {icon === 'right' && children}
    </button>
  );
};

ShineButton.propTypes = {
  size: oneOf(['small', 'medium', 'large']),
  rounded: oneOf(['default', 'full', 'half']),
  label: string,
  onClick: func,
  children: node,
  icon: string,
  styleName: string,
  className: string,
  variant: string,
};

ShineButton.defaultProps = {
  size: 'medium',
  rounded: 'default',
  onClick: undefined,
  children: null,
  icon: 'right',
  variant: 'primary',
};

const mapStateToProps = (state) => ({
  features: get(state, ['qt', 'config', 'features'], {}),
});

export default connect(mapStateToProps)(ShineButton);
