import React from 'react';
import { array, string, object, bool } from 'prop-types';
import Accordion from '../../atoms/accordion';
import { useSelector } from 'react-redux';
import get from 'lodash/get';

import './link-list.m.css';
import { isMobile } from 'react-device-detect';
import { getCookieValue, getUserStatus } from '../../../helpers/utils';

const LinkList = ({
  links,
  sectionHeading,
  gridType = 'col',
  styling,
  sectionDivider = 'default',
  accordion = false,
  className
}) => {
  const userData = useSelector((state) => get(state, ['userData']));
  const pageType = useSelector((state) => get(state, ['qt', 'pageType']));
  const formattedPageType =
    pageType === 'story-page' ? 'story' :
      pageType === 'home-page' ? 'home-page' :
        'section-page';
  return links ? (
    <Accordion disable={!accordion} className={className}>
      <div styleName="list">
        {sectionHeading && <h6 styleName="heading">{sectionHeading}</h6>}
        <div styleName={gridType === 'col' ? 'items vertical' : 'items horizontal'}>
          {links.map((i, index) => {
            return (
              <React.Fragment key={`${i.href}-${index}`}>
                <a
                  styleName="item"
                  href={i.href}
                  target={i.target}
                  rel={i.rel}
                  style={styling && styling.listItem}
                  onClick={() => {
                    const baseData = {
                      event: 'footer_interaction',
                      cta_text: i?.name || 'NA',
                      section_name: 'ndtv profit',
                      page_template: formattedPageType,
                      user_id: userData?.userProfile?.userId || 'NA',
                      user_status: getUserStatus(),
                      login_type: userData?.isLoggedIn ? 'logged-in' : 'logged-out',
                      language_hit: 'english',
                      Platform: isMobile ? 'mobile' : 'desktop',
                      SiteName: 'https://www.ndtvprofit.com'
                    };

                    if (typeof window !== 'undefined' && window.dataLayer) {
                      window.dataLayer.push(baseData);
                    } else {
                      console.error('Error: window.dataLayer is not available');
                    }
                  }}
                >
                  {i.name}
                </a>
                {sectionDivider === 'dot' && index !== links.length - 1 && <div styleName="dot" />}
              </React.Fragment>
            );
          })}
        </div>
      </div>
    </Accordion>
  ) : null;
}

LinkList.propTypes = {
  links: array,
  sectionHeading: string,
  gridType: string,
  sectionDivider: string,
  styling: object,
  accordion: bool,
  className: string
};

export default LinkList;
