import React, { useEffect, useRef, useState } from 'react';
import { bool, func, string } from 'prop-types';
import { connect, useSelector } from 'react-redux';
import get from 'lodash/get';
import DfpAd from '../../atoms/dfp-ad';
import { getUserDetails } from '../../../helpers/utils';
import MoengageHelper from '../../../helpers/moengage-helper';

import './mobile-bottom-sticky.m.css';
import { getUserStatus } from '../../../helpers/utils';

const MobileBottomSticky = ({ onHide, pageType, activate_mobileBottomSticky }) => {
  const userData = useSelector((state) => get(state, ['userData']));
  const [showMinimalExperience, setShowMinimalExperience] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const minimalAdExperienceClass = showMinimalExperience ? 'increase-z-index' : '';
  // const handleCrossBtn = (e) => {
  //   setShowMinimalExperience(!showMinimalExperience);
  //   onHide(showMinimalExperience);
  //   document.body.style.overflowY = showMinimalExperience ? 'inherit' : 'hidden';
  // };

  useEffect(() => {
    const handleResize = () => {
      const width = window.innerWidth;
      setIsMobile(width <= 767);
    };
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    const fetchUserType = async () => {
      if (window.dataLayer && userData) {
        function checkUserDetails() {
          const storedUserProperty = JSON.parse(localStorage.getItem('local_user_property')) || {};
          const userProperty = [...window.dataLayer]
            .reverse()
            .find((item) => item.event === 'user_property');
          const storedLoginStatus = storedUserProperty?.login_status;
          const loginStatus = userProperty?.login_status;
          const triggerUpdate = storedLoginStatus === loginStatus;

          if (userProperty && triggerUpdate) {
            // Check for differences, excluding 'gtm.uniqueEventId'
            const diffKeys = Object.keys({ ...userProperty, ...storedUserProperty })
              .filter(key => key !== 'gtm.uniqueEventId' &&
                key !== 'Platform' &&
                key !== 'user_type' &&
                JSON.stringify(userProperty[key]) !== JSON.stringify(storedUserProperty[key]));

            if (diffKeys.length > 0) {
              diffKeys.forEach(key => {
                console.log(`user has updated "${key}":`);
              });

              const baseData = {
                event: 'profile_updated',
                login_type: userData?.isLoggedIn ? 'logged-in' : 'logged-out',
                Platform: isMobile ? 'mobile' : 'desktop',
                SiteName: 'https://www.ndtvprofit.com'
              };
              window.dataLayer.push(baseData);
            }
          }
        }
        const baseDataLayerFields = {
          event: 'user_property',
          email: userData?.userProfile?.email || 'NA',
          user_id: userData?.userProfile?.userId || 'NA',
          full_name: userData?.userProfile?.fullName || 'NA',
          mobile: userData?.userProfile?.mobile || 'NA',
          login_status: 'logged-in',
          language_hit: 'english',
          Platform: isMobile ? 'mobile' : 'desktop',
          SiteName: 'https://www.ndtvprofit.com'
        };

        if (userData?.isLoggedIn === true && userData?.isChurnUser !== null) {
          console.log("✅ userData.isLoggedIn", userData?.isLoggedIn);
          localStorage.setItem('local_login_status', 'logged-in');
          if (userData?.isSubscriber === true && userData?.userSubscription !== null) {
            const planDetails = getSubscriptionDetails(userData);
            const subscriberDataLayerFields = {
              ...baseDataLayerFields,
              user_type: 'subscriber',
              subscription_status: 'active',
              ...planDetails,
            };
            window.dataLayer.push(subscriberDataLayerFields);
            console.log("✅ user_property triggered");
            checkUserDetails();
            localStorage.setItem('local_user_property', JSON.stringify(subscriberDataLayerFields));
          } else if (userData?.isSubscriber === false) {
            const nonSubscriberDataLayerFields = {
              ...baseDataLayerFields,
              user_type: 'non-subscriber',
              subscription_status: userData?.isChurnUser ? 'expired' : 'NA',
            };
            window.dataLayer.push(nonSubscriberDataLayerFields);
            console.log("✅ user_property triggered");
            checkUserDetails();
            localStorage.setItem('local_user_property', JSON.stringify(nonSubscriberDataLayerFields));
          }
          MoengageHelper.userLogin(userData);
        } else if (userData?.isLoggedIn === false) {
          const loggedOutDataLayerFields = {
            event: 'user_property',
            login_status: 'logged-out',
            user_type: getUserStatus(),
            subscription_status: 'NA',
            language_hit: 'english',
            Platform: isMobile ? 'mobile' : 'desktop',
            SiteName: 'https://www.ndtvprofit.com'
          };
          window.dataLayer.push(loggedOutDataLayerFields);
          checkUserDetails();
          localStorage.setItem('local_user_property', JSON.stringify(loggedOutDataLayerFields));
          localStorage.setItem('local_login_status', 'logged-out');
        }
      }
    };

    if (userData !== null) {
      fetchUserType();
    } else {
      console.log("❌ userData not available, user_property not triggering");
    }
  }, [userData?.isSubscriber, userData?.userSubscription?.active?.plan_name, userData?.isChurnUser]);

  function getSubscriptionDetails(userData) {
    const planType = userData?.userSubscription?.active?.subscription_type || 'NA';
    const planName = userData?.userSubscription?.active?.plan_name || 'NA';
    const daysPassed = userData?.userSubscription?.active?.duration_length || 'NA';
    const subscriptionStartDate = userData?.userSubscription?.active?.start_timestamp || 'NA';
    const subscriptionRenewDate = userData?.userSubscription?.active?.end_timestamp || 'NA';
    const planId = userData?.userSubscription?.active?.subscription_plan_id || 'NA';
    const planFinalAmount = userData?.userSubscription?.active?.payment_amount || 'NA';
    return {
      plan_type: planType,
      plan_name: planName,
      plan_duration: daysPassed,
      plan_start_date: subscriptionStartDate,
      plan_end_date: subscriptionRenewDate,
      plan_id: planId,
      plan_final_amount: planFinalAmount,
      payment_currency: 'INR',
    };
  }


  const [showAd, setShowAd] = useState(true);
  useEffect(() => {
    if (window.location.href.includes('/bq-brand-studio/')) {
      setShowAd(false);
    }
  }, []);
  const currentPath = useSelector((state) => get(state, ['qt', 'currentPath']));

  let scrollPercentage = 0;
  let hasScrolled25 = false;
  let hasScrolled50 = false;
  let hasScrolled75 = false;
  let hasScrolled100 = false;

  const calculateScrollPercentage = () => {
    if (typeof window !== 'undefined') {
      const scrollTop = window.scrollY;
      const skipDiv = document.getElementById('taboola-below-article-thumbnails');

      // Calculate document height while skipping the taboola ad container
      const documentHeight = skipDiv
        ? document.documentElement.scrollHeight - skipDiv.offsetHeight - window.innerHeight
        : document.documentElement.scrollHeight - window.innerHeight;

      if (documentHeight > 0) {
        scrollPercentage = (scrollTop / documentHeight) * 100;
        localStorage.setItem('scrollPercentage', scrollPercentage);
      } else {
        scrollPercentage = 0;
        localStorage.setItem('scrollPercentage', scrollPercentage);
      }
    }
  };

  const pushScrollDataToDataLayer = (scrollMilestone) => {

    if (window.dataLayer) {
      const formattedPageType =
        pageType === 'story-page' ? 'story' :
          pageType === 'home-page' ? 'home-page' :
            'section-page';
      const userProperty = JSON.parse(localStorage.getItem('local_user_property'));
      const userId = userProperty && userProperty.user_id ? userProperty.user_id : 'NA';
      const loginType = userProperty && userProperty.login_status ? userProperty.login_status : 'logged-out';

      const baseData = {
        event: 'page_scroll',
        scroll_percent: scrollMilestone + '%',
        page_template: formattedPageType || 'NA',
        user_id: userId,
        user_status: getUserStatus(),
        login_type: loginType,
        language_hit: 'english',
        Platform: isMobile ? 'mobile' : 'desktop',
        SiteName: 'https://www.ndtvprofit.com'
      };

      window.dataLayer.push(baseData);
    } else {
      console.error('Error: window.dataLayer is not available');
    }
  };

  const handleScroll = () => {
    calculateScrollPercentage();

    if (pageType !== 'story-page') {
      if (scrollPercentage >= 25 && !hasScrolled25) {
        pushScrollDataToDataLayer(25);
        hasScrolled25 = true;
      }
      if (scrollPercentage >= 50 && !hasScrolled50) {
        pushScrollDataToDataLayer(50);
        hasScrolled50 = true;
      }
      if (scrollPercentage >= 75 && !hasScrolled75) {
        pushScrollDataToDataLayer(75);
        hasScrolled75 = true;
      }
      if (scrollPercentage >= 100 && !hasScrolled100) {
        pushScrollDataToDataLayer(100);
        hasScrolled100 = true;
      }
    }
  };

  useEffect(() => {
    if (typeof window !== 'undefined') {
      if (pageType !== 'story-page') {
        window.addEventListener('scroll', handleScroll);
      }
      return () => {
        window.removeEventListener('scroll', handleScroll);
      };
    }
  }, [pageType]);

  return (
    <>
      {isMobile && activate_mobileBottomSticky &&
        showAd &&
        userData?.isSubscriber === false &&
        !currentPath.includes('/subscription') && (
          <div styleName={`sticky-wrapper ${minimalAdExperienceClass}`}>
            <div styleName={!showMinimalExperience ? 'sticky-responsive-ad-wrapper' : 'hide'}>
              <div styleName="sticky-responsive-ad">
                <DfpAd
                  adtype="MobileSticky"
                  adUnit="Sticky_320x75"
                  sizes={[
                    [320, 75],
                    [320, 50],
                  ]}
                />
              </div>
            </div>
          </div>
        )}
    </>
  );
};

MobileBottomSticky.propTypes = {
  onHide: func,
  pageType: string,
  isAmpPage: bool,
};

const mapStateToProps = (state) => {
  return {
    pageType: get(state, ['qt', 'pageType'], null),
    activate_mobileBottomSticky: get(state, ['qt', 'config', 'publisher', 'activate_mobileBottomSticky'], 'false'),
  };
};

export default connect(mapStateToProps, () => ({}))(MobileBottomSticky);
