/*eslint-disable*/
import React, { useState, useEffect } from 'react';
import { string, array, any, bool } from 'prop-types';
import { connect, useSelector } from 'react-redux';
import get from 'lodash/get';
import throttle from 'lodash/throttle';
import DfpAd from '../../atoms/dfp-ad';
import SvgIcon from '../../atoms/svg-icon';
import { BQLink } from '../../atoms/bqlink';
import HamburgerBtn from '../../molecules/hamburger-btn';
import MainMenu from '../../molecules/main-menu';
import MobileBottomSticky from '../../molecules/mobile-bottom-sticky';
import { sectionColor } from '../../../helpers/utils';
import LandscapeOrientation from '../../pages/Landscape-orientation';
import HeaderTop from './header-top/headerTop';
import MegaMenu from './mega-menu';
import Notifications from '../../notifications';
import TrackVisibility from '../../track-visibility';
import SearchDesktop from './search-desktop';
import { isDesktop } from 'react-device-detect';
import GlobalHeader from './global-header';
import './header.m.css';
import SponsorStrip from '../../molecules/sponsor-strip';
import BudgetCountdownStripHeader from './budget-countdown-strip-header';
import { watchLiveInteraction } from '../../../helpers/datalayer-utils';

const Header = (props) => {
  const {
    member,
    config,
    menu,
    pageType,
    verticalMenu,
    activate_sponsor_strip,
    activate_all_wap_ads,
    activate_wap1,
    activate_wap2,
    activate_wap3,
    activate_wap4,
    activate_wap5,
    activate_popup,
    activate_gutterAds,
  } = props;

  let prevScrollpos = null;

  const [showMenu, setShowMenu] = useState(false);
  const [showMenuBar, setShowMenuBar] = useState(false);
  const [showSubscriptionStrip, setShowSubscriptionStrip] = useState(true);
  const [searchOpen, setSearchOpen] = useState(false);
  const userData = useSelector((state) => get(state, ['userData']));
  const isSubscriber = useSelector((state) => get(state, ['userData', 'isSubscriber'], false));
  const userDetails = useSelector((state) => get(state, ['userData', 'userProfile'], false));
  const budgetHeaderStrip = useSelector((state) =>
    get(state, ['qt', 'config', 'features', 'budget'], null),
  );

  const activatePopUpAd =
    typeof window !== 'undefined' && (window.location.pathname.includes('lite') || activate_popup);

  useEffect(() => {
    if (document.documentElement.clientWidth < 768) {
      window.addEventListener('scroll', throttle(handleScroll, 50));
    }
    window.onscroll = () => fixGutterAds();
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const [pathName, setPath] = useState(false);

  useEffect(() => {
    const pathName = window.location.pathname;
    setPath(pathName === '/budget' || pathName === '/wadhwa' || pathName === '/');
  }, []);

  useEffect(() => {
    if (searchOpen) {
      setShowMenu(false);
    }
  }, [searchOpen]);

  const fixGutterAds = () => {
    const lhs = document.getElementById('gutter-lhs');
    const rhs = document.getElementById('gutter-rhs');
    if (lhs && rhs) {
      const offset = lhs.offsetTop;
      if (window.pageYOffset >= offset) {
        lhs.classList.add('sticky');
        rhs.classList.add('sticky');
      } else {
        lhs.classList.remove('sticky');
        rhs.classList.remove('sticky');
      }
    }
  };
  const handleScroll = () => {
    const header = document.getElementById('header');

    var currentScrollPos = window.pageYOffset;

    prevScrollpos = currentScrollPos <= 0 ? 0 : currentScrollPos;
  };

  const handleMenuOpen = () => {
    document.body.style.overflowY = 'hidden';
    setShowMenu(!showMenu);
    if (showMenu) {
      document.body.style.overflowY = 'auto';
    }
  };

  const handleMenuClose = () => {
    document.body.style.overflowY = 'auto';
    setShowMenu(false);
  };

  const onHide = (popup) => {
    const bottomAd = document.getElementById('mobile-bottom');
    if (popup) {
      bottomAd.classList.remove('hide-element');
    } else {
      bottomAd.classList.add('hide-element');
    }
  };

  const subscriptionStrip = () => {
    if (!isSubscriber && showSubscriptionStrip && !showMenu) {
      return (
        <div>
          <div className="container" styleName="subscription-strip">
            <SvgIcon type="starRedBg" source="image" height="20" width="20"></SvgIcon>
            <span>
              Get Unlimited Access <span>at ₹48/week</span>
            </span>
            <button
              onClick={() => (window.location.href = `/bq-blue-subscription?src=subs_hdr`)}
              styleName="subscribe-strip-btn"
            >
              Subscribe
            </button>
            <span className="icon-ic_close" onClick={() => setShowSubscriptionStrip(false)}></span>
          </div>
        </div>
      );
    }
  };

  const gutterAdsRhs = () => {
    return (
      <div className="responsive-ad-container align-right" id="gutter-rhs">
        <DfpAd adtype={'GutterAds1RHS'} adUnit="RHS_120x600" sizes={[[120, 600]]}></DfpAd>
      </div>
    );
  };

  const gutterAdsLhs = () => {
    return (
      <div className="responsive-ad-container align-left " id="gutter-lhs">
        <DfpAd adtype={'GutterAds1LHS'} adUnit="LHS_120x600" sizes={[[120, 600]]}></DfpAd>
      </div>
    );
  };

  const WapAds1 = () => {
    if (pageType === 'story-page') {
      return (
        <div className="height-zero">
          <DfpAd adtype={'Wap1x1_1'} adUnit={1} sizes={[[1, 1]]}></DfpAd>
        </div>
      );
    }
  };
  const WapAds2 = () => {
    if (pageType === 'story-page') {
      return (
        <div className="height-zero">
          <DfpAd adtype={'Wap1x1_2'} adUnit={2} sizes={[[1, 1]]}></DfpAd>
        </div>
      );
    }
  };
  const WapAds3 = () => {
    if (pageType === 'story-page') {
      return (
        <div className="height-zero">
          <DfpAd adtype={'Wap1x1_3'} adUnit={3} sizes={[[1, 1]]}></DfpAd>
        </div>
      );
    }
  };
  const WapAds4 = () => {
    if (pageType === 'story-page') {
      return (
        <div className="height-zero">
          <DfpAd adtype={'Wap1x1_4'} adUnit={4} sizes={[[1, 1]]}></DfpAd>
        </div>
      );
    }
  };
  const WapAds5 = () => {
    if (pageType === 'story-page') {
      return (
        <div className="height-zero">
          <DfpAd adtype={'Wap1x1_5'} adUnit={5} sizes={[[1, 1]]}></DfpAd>
        </div>
      );
    }
  };

  const shouldRenderSponsorStrip = pageType !== 'home-page' && activate_sponsor_strip;
  const [triggerAd, setTriggerAd] = useState(true);
  useEffect(() => {
    // Always enable gutterads if 'lite' is in the pathname
    if (window.location.pathname.includes('lite')) {
      setTriggerAd(true);
      return; // No need to check further conditions
    }

    // Disable ads if URL contains '/bq-brand-studio/' or activate_gutterAds is explicitly false
    if (
      window.location.href.includes('/bq-brand-studio/') ||
      (typeof activate_gutterAds !== 'undefined' && !activate_gutterAds)
    ) {
      setTriggerAd(false);
    }
  }, []);

  const [showAd, setShowAd] = useState(false);
  useEffect(() => {
    const handleScroll = () => {
      if (
        !isSubscriber &&
        !window.location.href.includes('/bq-brand-studio/') &&
        !window.location.href.includes('video-page-test')
      ) {
        setShowAd(true);
        window.removeEventListener('scroll', handleScroll);
      }
    };
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [isSubscriber]);
  const budgetStrip = showMenuBar && pathName && budgetHeaderStrip?.header_count_strip;
  const independence = config?.features?.independence_day;
  return (
    <div styleName="header-wrapper">
      <div styleName="landscape-orientation">
        <LandscapeOrientation></LandscapeOrientation>
      </div>
      <header styleName="header" style={{borderBottom: pageType && pageType !== 'home-page' ? '1px solid var(--iron-color)' : 'none'}}>
        {showMenuBar && <BudgetCountdownStripHeader isPath={pathName}></BudgetCountdownStripHeader>}
        {independence?.active && independence.header.flag_left && independence.header.flag_left && (
          <>
            <img
              src={independence.header.flag_left}
              height="57px"
              styleName="flag-left"
              className="desktop-only"
            ></img>
            <img
              src={independence.header.flag_right}
              height="57px"
              width="93px"
              styleName="flag-right"
              className="desktop-only"
            ></img>
          </>
        )}

        <GlobalHeader features={config.features}></GlobalHeader>
        {independence?.active && (
          <div styleName="top-flag" className="mobile-only">
            <div styleName="default saffron"></div>
            <div styleName="default"></div>
            <div styleName="default green"></div>
          </div>
        )}

        <HeaderTop {...props}></HeaderTop>
        <TrackVisibility onHide={() => setShowMenuBar(true)} onVisible={() => setShowMenuBar(false)}>
          <div styleName={`menu-wrapper ${showMenuBar ? 'menuSticky' : ''} ${budgetStrip ? 'menu-position' : ''}`}>
            <div className="container" styleName={`menu-container ${showMenuBar ? 'left-align' : ''} ${independence?.active && showMenuBar ? 'menu-increase' : ''}`}>
              <HamburgerBtn
                onMenuToggle={handleMenuOpen}
                isMegaMenuOpen={showMenu}
                showMenuBar={showMenuBar}
                budgetStrip={budgetStrip}
              ></HamburgerBtn>
              <MegaMenu
                userDetails={userDetails}
                showMenu={showMenu}
                handleMenuClose={handleMenuClose}
                menu={menu}
                isSubscriber={isSubscriber}
                showMenuBar={showMenuBar}
              ></MegaMenu>
              <MainMenu showMenuBar={showMenuBar} pageType={pageType}></MainMenu>
              <div styleName="white-shadow" className="mobile-only"></div>
              {showMenuBar ? (
                <div onClick={() => watchLiveInteraction(userData, pageType, 'header')}>
                  <BQLink
                    href="/videos?src=topnav_livetv"
                    styleName="live_tv_scroll"
                    className="mobile-only"
                  >
                    <SvgIcon
                      type="live_tv"
                      iconStyle={{ width: '32px', height: '27px' }}
                      source="image"
                    ></SvgIcon>
                  </BQLink>
                </div>
              ) : null}
              <div styleName={`notification-and-search ${showMenuBar ? 'menuScrolled' : ''}`} className="desktop-only">
                {config.features.notifications && config.features.notifications.enabled && (
                  <Notifications member={member} config={config}></Notifications>
                )}
                <SearchDesktop setSearchOpen={setSearchOpen} showMenuBar={showMenuBar}></SearchDesktop>
                <div onClick={() => watchLiveInteraction(userData, pageType, 'header')}>
                  <BQLink
                    href="/live-tv?src=topnav_livetv"
                    className="desktop-only"
                    title="Live TV"
                  >
                    <SvgIcon
                      type="live_tv"
                      iconStyle={{ width: 'auto', height: '28px' }}
                      source="image"
                    ></SvgIcon>
                  </BQLink>
                </div>
              </div>
            </div>
          </div>
        </TrackVisibility>
        <MobileBottomSticky></MobileBottomSticky>
      </header>
      <div>{shouldRenderSponsorStrip && <SponsorStrip></SponsorStrip>}</div>
      {searchOpen && <div styleName="menu-popover-shadow"></div>}
      <div>
        {triggerAd && isSubscriber === false && isDesktop && !showMenu ? gutterAdsLhs() : null}
        {triggerAd && isSubscriber === false && isDesktop ? gutterAdsRhs() : null}
      </div>
      {activate_all_wap_ads && !isDesktop && isSubscriber === false && (
        <div>
          {activate_wap1 ? WapAds1() : null}
          {activate_wap2 ? WapAds2() : null}
          {activate_wap3 ? WapAds3() : null}
          {activate_wap4 ? WapAds4() : null}
          {activate_wap5 ? WapAds5() : null}
        </div>
      )}
      {showAd && isSubscriber === false && activatePopUpAd && (
        <div className="height-zero">
          <DfpAd
            adtype="PopUpAcrossAllSectionsAndPages"
            adUnit="Interstitial_1x1"
            sizes={[[1, 1]]}
          ></DfpAd>
        </div>
      )}
    </div>
  );
};

Header.propTypes = {
  pageType: string.isRequired,
  sectionColor: string,
  menu: array.isRequired,
  verticalMenu: array,
  config: any.isRequired,
  member: any,
  isSubscriber: bool,
  activate_all_wap_ads: bool,
  activate_wap1: bool,
  activate_wap2: bool,
  activate_wap3: bool,
  activate_wap4: bool,
  activate_wap5: bool,
  activate_popup: bool,
  activate_gutterAds: bool,
};

const mapStateToProps = (state) => {
  const { features = {}, publisher = {}, integrations } = state.qt.config;
  const { pianoUserDetails = {} } = state;
  return {
    menu: get(state, ['qt', 'data', 'navigationMenu', 'WebSidebarMenu'], []),
    sectionColor:
      get(state, ['qt', 'pageType'], []) === 'section-page'
        ? sectionColor(
            get(state, ['qt', 'config'], []),
            get(state, ['qt', 'data', 'section', 'id'], []),
          )
        : '',
    pageType: get(state, ['qt', 'pageType'], ''),
    activate_sponsor_strip: get(
      state,
      ['qt', 'config', 'publisher', 'activate_sponsor_strip'],
      'true',
    ),
    activate_all_wap_ads: get(state, ['qt', 'config', 'publisher', 'activate_all_wap'], 'true'),
    activate_wap1: get(state, ['qt', 'config', 'publisher', 'activate_wap1'], 'true'),
    activate_wap2: get(state, ['qt', 'config', 'publisher', 'activate_wap2'], 'true'),
    activate_wap3: get(state, ['qt', 'config', 'publisher', 'activate_wap3'], 'true'),
    activate_wap4: get(state, ['qt', 'config', 'publisher', 'activate_wap4'], 'true'),
    activate_wap5: get(state, ['qt', 'config', 'publisher', 'activate_wap5'], 'true'),
    activate_popup: get(state, ['qt', 'config', 'publisher', 'activate_popup'], 'true'),
    activate_gutterAds: get(state, ['qt', 'config', 'publisher', 'activate_gutterAds'], 'true'),
    config: {
      features,
      publisher,
      integrations,
    },
    member: pianoUserDetails.member || null,
  };
};

function mapDispatchToProps(dispatch) {
  return {
    sendUserStatus: () => {
      dispatch({
        type: 'BQ_USER_STATUS',
      });
    },
    sendSubscriptionDetails: (subscriptionDetails) => {
      dispatch({
        type: 'BQ_SUBSCRIPTION_DETAILS',
        data: subscriptionDetails,
      });
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Header);
