import React, { useEffect, useState } from 'react';
import UserRoundIcon from '../../../atoms/icons/user-round-icon';
import {
  CloseIcon,
  TelevisionClassicIcon,
  CellPhoneIcon,
  HeartIcon,
  LifeBuoyIcon,
  InformationOutlineIcon,
  CellphoneDownloadIcon,
} from '../../../atoms/material-icons';
import { connect, useSelector, useDispatch } from 'react-redux';
import SvgIcon from '../../../atoms/svg-icon';
import './mega-menu.m.css';
import { BQLink } from '../../../atoms/bqlink';
import { CONTACT, SOCIAL_SHARE, PATH } from '../../../../constants';
import get from 'lodash/get';
import menuIcons from '../../../atoms/material-icons/icon-mapping';
import AngleDown from '../../../atoms/icons/angle-down';
import Icon from '@mdi/react';
import {
  mdiAccountCircle,
  mdiBookmark,
  mdiReceiptText,
  mdiSackPercent,
  mdiStarFourPoints,
  mdiChevronRight,
  mdiHome,
  mdiWeb,
} from '@mdi/js';
import LogoutIcon from '../../../atoms/icons/logout';
import {
  getSSOLogoutUrl,
  getSSOLoginUrl,
  getSSORedirectUrl,
} from '../../../../helpers/subscription-helper';
import {
  clearSSOUserDetails,
  getUserInitials,
  getUserStatus,
} from '../../../../helpers/utils';
import { BookmarkIcon } from '../../../atoms/material-icons';

import Premium from '../../../atoms/logo/premium';
import {
  accountInteraction,
  downloadAppDl,
  languageSelection,
  signInClick,
  subscribeClick,
  watchLiveInteraction,
} from '../../../../helpers/datalayer-utils';
import { followIconsDl } from '../../../../helpers/datalayer-utils';

const mobileMenu = [
  {
    id: 1,
    name: 'Profile',
    icon: mdiAccountCircle,
    link: '/my-account/profile?src=menu_user',
  },
  {
    id: 2,
    name: 'My Subscription',
    icon: mdiReceiptText,
    link: '/my-account/subscription?src=menu_subscribe',
  },
  {
    id: 3,
    name: 'Bookmarks',
    icon: mdiBookmark,
    link: '/my-account/bookmarks?src=menu_bookmarks',
  },
];

const MegaMenu = ({
  showMenu,
  userDetails,
  handleMenuClose,
  menu,
  isSubscriber,
  integrations,
  showMenuBar,
  clientHost,
}) => {
  const dispatch = useDispatch();
  const [currentOpenMenu, setCurrentOpenMenu] = useState('');
  const [isLoading, setLoading] = useState(true);
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [isUpperSize, setUpperSize] = useState(true);
  const [appDownloadLink, setAppDownloadLink] = useState('');
  const [scrollY, setScrollY] = useState(0);
  const [isHomePage, setHomePage] = useState(false);
  const [isPodcast, setPodcast] = useState(false);
  const [showApp, setShowApp] = useState(false);
  const getPageTypeFromUrl = () => {
    if (typeof window === 'undefined') return 'unknown-page-type';
    const path = window.location.pathname;
    if (path === '/') {
      return 'home-page';
    }
    const pathParts = path.replace(/^\/|\/$/g, '').split('/');
    if (pathParts.length === 1) {
      return 'section-page';
    } else if (pathParts.length >= 2) {
      return 'story-page';
    }
    return 'unknown-page-type';
  };

  const pageType = getPageTypeFromUrl();
  if (typeof window !== 'undefined' && window.localStorage) {
    window.localStorage.setItem('pageTypeValue', pageType);
  }
  const formattedPageType =
    pageType === 'story-page' ? 'story' : pageType === 'home-page' ? 'home-page' : 'section-page';
  const SUBSCRIPTION_DAYS = useSelector((state) =>
    get(state, ['qt', 'config', 'features', 'subscription'], null),
  );
  const SSO_LOGIN_URL = useSelector((state) =>
    get(state, ['qt', 'config', 'features', 'subscription', 'login_url'], null),
  );
  const SSO_LOGOUT_URL = useSelector((state) =>
    get(state, ['qt', 'config', 'features', 'subscription', 'logout_url'], null),
  );
  const worldsummit = useSelector((state) =>
    get(state, ['qt', 'config', 'features', 'worldsummit'], {}),
  );
  const [redirectUrl, setRedirectUrl] = useState('');
  const [loginUrl, setLoginUrl] = useState('');
  const [isOpen, setOpen] = useState(false);
  const userData = useSelector((state) => get(state, ['userData']));
  const [bookmarkUrl, setBookmarkUrl] = useState(clientHost);

  const handleMenuExpand = (menuName) => {
    if (currentOpenMenu === menuName) {
      setCurrentOpenMenu('');
    } else {
      setCurrentOpenMenu(menuName);
    }
  };

  const handleClick = () => {
    const width = window.innerWidth;
    if (width <= 767) {
      setOpen(!isOpen);
    } else {
      setOpen(false);
      window.location.href = '/my-account/profile?src=menu_user';
    }
  };
  const handleClickItem = (menu, formattedPageType) => {
    const baseData = {
      event: 'navigation_interaction',
      cta_text: 'NA',
      select_type: 'hamburger',
      page_template: formattedPageType || 'NA',
      user_id: userData?.userProfile?.userId || 'NA',
      user_status: getUserStatus(),
      login_type: userData?.isLoggedIn ? 'logged-in' : 'logged-out',
      language_hit: 'english',
      Platform: isMobile ? 'mobile' : 'desktop',
      SiteName: 'https://www.ndtvprofit.com'
    };

    if (menu?.completeUrl === '/premium') {
      baseData.cta_text = 'premium';
    } else if (menu?.completeUrl === '/budget') {
      baseData.cta_text = 'budget';
    } else if (typeof menu === 'string') {
      baseData.cta_text = menu;
    } else if (menu && menu.title) {
      baseData.cta_text = menu?.title;
    } else {
      baseData.cta_text = 'NA';
    }

    if (typeof window !== 'undefined' && window.dataLayer) {
      window.dataLayer.push(baseData);
    } else {
      console.error('Error: window.dataLayer is not available');
    }
  };

  useEffect(() => {
    if (typeof window !== 'undefined' && window.localStorage) {
      const storedData = localStorage.getItem('dataLayerSideMainMenuClick');

      if (storedData) {
        try {
          const dataLayerData = JSON.parse(storedData);
          window.dataLayer = window.dataLayer || [];
          window.dataLayer.push(dataLayerData);
          localStorage.removeItem('dataLayerSideMainMenuClick');
        } catch (error) {
          console.error('Error parsing stored data:', error);
        }
      }
    }
  }, [menu.completeUrl]);

  useEffect(() => {
    if (!showMenu) {
      setCurrentOpenMenu('');
    }
    const path = window.location.pathname;
    const podcast = `/podcasts/all-you-need-to-know/${integrations?.podcast?.PODCAST_ID}`;
    const isPodcast = path === podcast || new RegExp(`^${podcast}/\\d+$`).test(path);
    setPodcast(isPodcast);
    const isHomePage = path === '/' || isPodcast;
    setHomePage(isHomePage);
  }, [showMenu]);

  useEffect(() => {
    const url = new URL(window?.location?.href);
    getSSORedirectUrl(url).then((pageUrl) => {
      setRedirectUrl(pageUrl);
    });
    const userAgent = window.navigator.userAgent;

    if (/iPhone|iPad|iPod/i.test(userAgent)) {
      setAppDownloadLink('https://apps.apple.com/in/app/ndtv-profit/id502359431');
    } else if (/Android/i.test(userAgent)) {
      setAppDownloadLink(
        'https://play.google.com/store/apps/details?id=com.ndtv.ndtvprofit&pcampaignid=web_share',
      );
    } else {
      setAppDownloadLink('/');
    }
    setScrollY(window.scrollY);
    setLoading(false);
  }, []);
  useEffect(() => {
    getSSOLoginUrl(
      integrations?.sso?.client_id,
      integrations?.sso?.client_secret,
      redirectUrl,
      SSO_LOGIN_URL,
    ).then((pageUrl) => {
      setLoginUrl(pageUrl);
      const bUrl = !!userDetails
        ? PATH.MYBQ_PAGE + PATH.MYACCOUNT_BOOKMARKS
        : pageUrl.replace(
          pageUrl.substring(pageUrl.indexOf('redirectUrl'), pageUrl.length),
          `redirectUrl=${clientHost}${PATH.MYBQ_PAGE}${PATH.MYACCOUNT_BOOKMARKS}`,
        );
      setBookmarkUrl(bUrl);
    });
  }, [redirectUrl, userDetails]);

  useEffect(() => {
    const footerElement = document.getElementById('footer');
    if (footerElement) {
      const windowHeight = window.innerHeight;
      const footerRect = footerElement.getBoundingClientRect();
      const footerPosition = windowHeight * 0.8;
      setShowApp(footerRect.top > footerPosition);
    }
  }, [scrollY]);

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollY = window.scrollY;
      if (currentScrollY > scrollY && !isCollapsed) {
        setTimeout(() => {
          setIsCollapsed(true);
        }, 1000);
      }
      if (currentScrollY < scrollY && isCollapsed && isUpperSize) {
        setIsCollapsed(false);
        setTimeout(() => {
          setIsCollapsed(true);
        }, 1500);
        setUpperSize(false);
      }
      setScrollY(currentScrollY);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [scrollY, isCollapsed]);

  const onLogout = async () => {
    window.location = await getSSOLogoutUrl(
      integrations?.sso?.client_id,
      integrations?.sso?.client_secret,
      getSSORedirectUrl(new URL(window?.location?.href)),
      SSO_LOGOUT_URL,
    );
    await clearSSOUserDetails(dispatch);
  };

  const ShineStrip = (className) => {
    return (
      <div
        styleName="profit-item shine-btn shine"
        className={className}
        onClick={() => subscribeClick(userData, formattedPageType, 'hamburger')}
      >
        <Icon path={mdiStarFourPoints} size="24px" color="#CE9445" />
        <BQLink href={`${PATH.SUBSCRIPTION_PAGE}?src=subs_menu`} styleName="profit-head">
          <div styleName="title">
            <span styleName="desc-txt">Get Unlimited Access</span>
            <span>{`Subscribe at just ₹${SUBSCRIPTION_DAYS?.subscription_btn_amount}/day`}</span>
          </div>
          <Icon path={mdiChevronRight} size="20px" color="#FFFFFF6E" />
        </BQLink>
      </div>
    );
  };

  const LoginForm = (className) => {
    return (
      <div styleName="user-greet-section" className={className}>
        <div styleName="greeting">
          <div styleName={`user-initials ${isSubscriber ? 'premium-border' : ''}`}>
            <div styleName="inner-circle">
              {!!userDetails && !!userDetails.fullName ? (
                <span styleName="initials">{getUserInitials(userDetails) || 'R'}</span>
              ) : (
                <UserRoundIcon
                  color={'iron-color-40'}
                  fontSize="28px"
                  styleName="user-icon-default"
                />
              )}
            </div>
          </div>
          <div styleName="name-ctx">
            {!!userDetails && !!userDetails.userId ? (
              <div onClick={() => handleClick()} styleName="profile-wrap">
                <div>
                  <div styleName="name">
                    {userDetails.fullName || getUserInitials(userDetails) || 'Profit Reader'}&nbsp;
                    <img
                      styleName={`right-icon ${(userDetails.fullName || userDetails.email) && !isOpen
                        ? 'rotated-down'
                        : (userDetails.fullName || userDetails.email) && isOpen
                          ? 'rotated-up'
                          : ''
                        }`}
                      src="/icons/ic_chevron_right.svg"
                      alt="Right icon"
                      loading="lazy"
                    />
                  </div>
                </div>
                <div styleName="tag-wrap">
                  <div styleName={`sub-tag ${isSubscriber ? 'premium-tag' : 'basic-tag'}`}>
                    {isSubscriber ? <span>✦ MEMBER</span> : <span>BASIC</span>}
                  </div>
                </div>
              </div>
            ) : (
              <React.Fragment>
                <div styleName="name default-name">Hello Reader</div>
                <div onClick={() => signInClick(userData, pageType, 'Sign In / Register', 'hamburger')}>
                  <BQLink href={loginUrl} target="_self">
                    <div styleName="sign-in">
                      Sign In / Register&nbsp;
                      <img
                        styleName="right-icon"
                        src="/icons/ic_chevron_right.svg"
                        alt="Right icon"
                        loading="lazy"
                      />
                    </div>
                  </BQLink>
                </div>
              </React.Fragment>
            )}
            <div></div>
            <div></div>
          </div>
        </div>
        {!!userDetails && (
          <div
            styleName="logout-button"
            onClick={() => {
              accountInteraction(userData, formattedPageType, 'hamburger', 'logout');
              onLogout();
            }}
            className="desktop-only"
          >
            <span>
              <div styleName="icon">
                <LogoutIcon color="border6-color" />
              </div>
              Logout
            </span>
          </div>
        )}
        <div onClick={() => watchLiveInteraction(userData, formattedPageType, 'hamburger')}>
          <BQLink
            href="/live-tv?src=menu_livetv"
            styleName="live-tv-button"
            className="desktop-only"
          >
            <TelevisionClassicIcon color="#ffff" size="19px" />
            <span>LIVE TV</span>
          </BQLink>
        </div>
      </div>
    );
  };

  const [isMobile, setIsMobile] = useState(true);

  useEffect(() => {
    const handleResize = () => {
      const width = window.innerWidth;
      setIsMobile(width <= 768);
    };
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const handleLanguageClick = (language, selectType) => {
    const baseData = {
      event: 'language_selection',
      page_template: formattedPageType,
      user_id: userData?.userProfile?.userId || 'NA',
      user_status: getUserStatus(),
      login_type: userData?.isLoggedIn ? 'logged-in' : 'logged-out',
      select_type: selectType,
      language_type: language,
      language_hit: 'english',
      Platform: isMobile ? 'mobile' : 'desktop',
      SiteName: 'https://www.ndtvprofit.com'
    };

    if (typeof window !== 'undefined' && window.dataLayer) {
      window.dataLayer.push(baseData);
    } else {
      console.error('Error: window.dataLayer is not available');
    }
  };

  return (
    <>
      <div styleName={`megamenu-wrapper ${showMenu ? 'showMenu' : 'hide-menu'}`} id="sidemenu-wrap">
        <div styleName="megamenu-top-section">
          <div styleName="logo-section">
            <div>
              <SvgIcon
                type="ndtv_group_logo.webp"
                width="168px"
                height="29px"
                source="image"
                isSvg={false}
                loading="lazy"
              />
            </div>
            <div styleName="language-toggle" className="desktop-only">
              <div styleName="toggle-btn-container">
                <button
                  styleName="toggle-btn toggle-selected"
                  onClick={() => handleLanguageClick('english', 'hamburger')}
                >
                  English
                </button>
                <BQLink
                  href="https://hindi.ndtvprofit.com?utm_source=profit&utm_medium=referral&utm_campaign=language"
                  target="_blank"
                  rel="noreferrer"
                >
                  <button
                    styleName="toggle-btn"
                    onClick={() => handleLanguageClick('hindi', 'hamburger')}
                  >
                    Hindi
                  </button>
                </BQLink>
              </div>
            </div>
            <div
              styleName="close-icon"
              onClick={() => {
                setOpen(false);
                handleMenuClose();
              }}
            >
              <CloseIcon color="#5d6d77" />
            </div>
          </div>
          {LoginForm('desktop-only')}
        </div>

        {isLoading ? (
          <div styleName="greeting-skeleton">
            <div styleName="profile-skeleton" />
            <div styleName="name-skeleton">
              <div styleName="large-skeleton"></div>
              <div styleName="small-skeleton"></div>
            </div>
          </div>
        ) : (
          <>{LoginForm('mobile-only')}</>
        )}

        {isOpen && (
          <div styleName="profile-list">
            {mobileMenu?.map((dl) =>
              !isSubscriber && dl.name === 'My Subscription' ? null : (
                <div
                  styleName="list"
                  key={dl.id || dl.name}
                  onClick={() => accountInteraction(userData, pageType, 'hamburger', dl.name)}
                >
                  <Icon path={dl.icon} size="20px" color="#b7bfc4" />
                  <BQLink href={dl.link}>{dl.name}</BQLink>
                </div>
              ),
            )}
          </div>
        )}
        {!isLoading && !isSubscriber && ShineStrip('mobile-only')}
        <div styleName="megamenu-bottom-section">
          <div styleName="megamenu-left">
            <div styleName="menu-list">
              {!isLoading && !isSubscriber && ShineStrip('desktop-only')}
              {showMenuBar && (
                <div
                  styleName="menu-item"
                  style={{ color: '#5c6d77' }}
                  onClick={() => (window.location.href = '/?src=top_nav')}
                  className="mobile-only"
                >
                  <div styleName="icon-wrapper">
                    <Icon path={mdiHome} size={'20px'} color="#B7BFC4" />
                  </div>
                  <div>Home</div>
                </div>
              )}
              {menu.length > 0
                ? menu.slice(0, -1).map((item, index) => {
                  const icon = get(item, ['data', 'icon-code'], 'default');
                  const menuExpanded = currentOpenMenu === item.title;
                  return (
                    <React.Fragment key={index}>
                      <div
                        styleName="menu-item"
                        onClick={() => {
                          if (item.title === worldsummit.name) {
                            window.open(`${worldsummit?.link}${worldsummit?.utm}`, '_blank');
                          } else if (!item.children.length) {
                            window.location.href = item.completeUrl + '?src=menu';
                          }
                          handleMenuExpand(item.title);
                          handleClickItem(item, formattedPageType);
                        }}
                        key={item?.id || index}
                        style={{ color: item?.data?.color ? item?.data?.color : '#5c6d77' }}
                      >
                        <div styleName="icon-wrapper">
                          {item.completeUrl === '/budget' ? (
                            <Icon path={mdiSackPercent} size={'20px'} color="#B7BFC4" />
                          ) : item.completeUrl === '/premium' ? (
                            <Icon path={mdiStarFourPoints} size={'20px'} color="#CE9445" />
                          ) : item.title === worldsummit.name ? (
                            <Icon path={mdiWeb} size={'20px'} color="#B7BFC4" />
                          ) : (
                            <>
                              {Object.keys(menuIcons).includes(icon) !== true
                                ? menuIcons.default
                                : menuIcons[icon]}
                            </>
                          )}
                        </div>
                        <div>
                          {item.completeUrl === '/premium' ? (
                            <Premium fontSize={'18px'} />
                          ) : item.completeUrl === '/budget' ? (
                            <img
                              src="/icons/budget-logo.svg"
                              style={{ marginBottom: '0px' }}
                              loading="lazy"
                              height="12px"
                            />
                          ) : item.title === worldsummit.name ? (
                            <img
                              src={worldsummit?.desk_img}
                              height={worldsummit?.desk_height}
                              width={worldsummit?.width}
                              alt={worldsummit?.name}
                            />
                          ) : (
                            item.title
                          )}
                        </div>
                        {item.completeUrl === '/the-latest' ? <div styleName="dot"></div> : null}
                        {item.title === 'Tools' ? <div styleName="new-badge">New</div> : null}
                        {item.children.length > 0 ? (
                          <div
                            styleName="drop-down-icon"
                            style={{
                              transform: !menuExpanded ? 'rotate(0deg)' : 'rotate(-180deg)',
                            }}
                          >
                            <AngleDown fontSize="6px" />
                          </div>
                        ) : null}
                      </div>
                      <div
                        styleName={`nested-items ${currentOpenMenu === item.title ? 'show-nested' : 'hide-nested'
                          } ${item.completeUrl === '/premium' ? 'show-nested-bg' : ''}`}
                      >
                        {item.children.map((childItem, i) => (
                          <div onClick={() => handleClickItem(childItem, formattedPageType)} key={i}>
                            <BQLink href={`${childItem.completeUrl}?src=menu` || '/'}>
                              {childItem.completeUrl === '/budget' ? (
                                <img
                                  src="/icons/budget-logo.svg"
                                  style={{ marginBottom: '0px' }}
                                  loading="lazy"
                                  height="12px"
                                />
                              ) : (
                                childItem.title
                              )}
                            </BQLink>
                          </div>
                        ))}
                      </div>
                    </React.Fragment>
                  );
                })
                : null}
            </div>
          </div>
          <div styleName="megamenu-right">
            <div styleName="last-menu-item-section">
              {menu.length > 0 ? (
                <>
                  <div styleName="last-menu-item">{menu[menu.length - 1].title}</div>
                  {menu[menu.length - 1].children.map((item, index) => (
                    <div
                      styleName="last-item-children"
                      href={`${item.completeUrl}?src=menu` || '/'}
                      onClick={() => {
                        window.location.href = item.completeUrl + '?src=menu';
                        handleClickItem(item, formattedPageType);
                      }}
                      key={item?.id || index}
                    >
                      {item.title}
                    </div>
                  ))}
                </>
              ) : null}
            </div>
            <div styleName="user-options-with-social">
              <div styleName="user-options-section">
                <div
                  onClick={() => accountInteraction(userData, pageType, 'hamburger', 'bookmarks')}
                >
                  <BQLink href={bookmarkUrl} className="desktop-only">
                    <BookmarkIcon color="#8F9DA5" size="24px" />
                    <span>Bookmarks</span>
                  </BQLink>
                </div>
                <div onClick={() => handleClickItem('Support', formattedPageType)}>
                  <BQLink href={`mailto:${CONTACT.SUPPORT_ID}`}>
                    <LifeBuoyIcon color="#8F9DA5" size="24px" />
                    <span>Support</span>
                  </BQLink>
                </div>
                <div onClick={() => handleClickItem('About NDTV Profit', formattedPageType)}>
                  <BQLink href="/about-us?src=menu">
                    <InformationOutlineIcon color="#8F9DA5" size="24px" />
                    <span>About NDTV Profit</span>
                  </BQLink>
                </div>
                {!!userDetails && (
                  <span
                    styleName="logout-button mobile-logout"
                    onClick={() => {
                      accountInteraction(userData, pageType, 'hamburger', 'logout');
                      onLogout();
                    }}
                    className="mobile-only"
                  >
                    <span>
                      <div styleName="icon">
                        <LogoutIcon color="border6-color" />
                      </div>
                      Logout
                    </span>
                  </span>
                )}
              </div>
              <div styleName="social-section" className="mobile-only">
                <div styleName="socials-title">FOLLOW NDTV Profit ON</div>
                <div styleName="social-icons-footer-menu">
                  <BQLink href={SOCIAL_SHARE.FACEBOOK} target="_blank">
                    <div onClick={() => followIconsDl('facebook', formattedPageType, userData, 'hamburger')}>
                      <SvgIcon type="facebook_grey" width="36px" height="36px" source="image" />
                    </div>
                  </BQLink>
                  <BQLink href={SOCIAL_SHARE.INSTAGRAM} target="_blank">
                    <div
                      onClick={() => followIconsDl('instagram', formattedPageType, userData, 'hamburger')}
                    >
                      <SvgIcon type="instagram_grey" width="36px" height="36px" source="image" />
                    </div>
                  </BQLink>
                  <BQLink href={SOCIAL_SHARE.LINKEDIN} target="_blank">
                    <div onClick={() => followIconsDl('linkedin', formattedPageType, userData, 'hamburger')}>
                      <SvgIcon type="linkedin_grey" width="36px" height="36px" source="image" />
                    </div>
                  </BQLink>
                  <BQLink href={SOCIAL_SHARE.YOUTUBE} target="_blank">
                    <div onClick={() => followIconsDl('youtube', formattedPageType, userData, 'hamburger')}>
                      <SvgIcon type="youtube_grey" width="36px" height="36px" source="image" />
                    </div>
                  </BQLink>
                  <BQLink href={SOCIAL_SHARE.TWITTER} target="_blank">
                    <div onClick={() => followIconsDl('twitter', formattedPageType, userData, 'hamburger')}>
                      <SvgIcon type="x_grey" width="36px" height="36px" source="image" />
                    </div>
                  </BQLink>
                  <BQLink href={SOCIAL_SHARE.TELEGRAM} target="_blank">
                    <div onClick={() => followIconsDl('telegram', formattedPageType, userData, 'hamburger')}>
                      <SvgIcon type="telegram_grey" width="36px" height="36px" source="image" />
                    </div>
                  </BQLink>
                </div>
              </div>
              <div styleName="app-promotion-section" className="mobile-only">
                <div styleName="download-app-title">DOWNLOAD THE APP</div>
                <div styleName="appstore-playstore-links">
                  <BQLink
                    href="https://play.google.com/store/apps/details?id=com.ndtv.ndtvprofit&pcampaignid=web_share"
                    target="_blank "
                    rel="noopener noreferrer nofollow"
                  >
                    <div
                      onClick={() =>
                        downloadAppDl('play store', userData, formattedPageType, 'hamburger menu')
                      }
                    >
                      <SvgIcon
                        type="playstore"
                        width="100%"
                        height="42px"
                        source="image"
                        isSvg={false}
                        loading="lazy"
                      />
                    </div>
                  </BQLink>
                  <BQLink
                    href="https://apps.apple.com/in/app/ndtv-profit/id502359431"
                    target="_blank "
                    rel="noopener noreferrer nofollow"
                  >
                    <div
                      onClick={() =>
                        downloadAppDl('app store', userData, formattedPageType, 'hamburger menu')
                      }
                    >
                      <SvgIcon
                        type="appstore"
                        width="100%"
                        height="42px"
                        source="image"
                        isSvg={false}
                        loading="lazy"
                      />
                    </div>
                  </BQLink>
                </div>
                <div styleName="app-version">
                  <CellPhoneIcon color={'var(--fourth-color)'} size="18px" />
                  <span>v1.0.1</span>
                </div>
                <div styleName="app-message">
                  Made with <HeartIcon color="var(--submarine)" size="18px" />
                  by Business Experts
                </div>
              </div>
            </div>
          </div>
          <div styleName="language-toggle-with-live-tv" className="mobile-only">
            <div onClick={() => languageSelection(formattedPageType, userData, 'hamburger')}>
              <BQLink
                styleName="language-toggle-hindi"
                href="https://hindi.ndtvprofit.com?utm_source=profit&utm_medium=referral&utm_campaign=language"
                target="_blank"
                rel="noreferrer"
              >
                Switch to हिंदी
              </BQLink>
            </div>
            <div onClick={() => watchLiveInteraction(userData, formattedPageType, 'hamburger')}>
              <BQLink href="/live-tv?src=menu_livetv" styleName="live-tv-sidebar-footer">
                <TelevisionClassicIcon color="#ffff" size="19px" />
                <span>LIVE TV</span>
              </BQLink>
            </div>
          </div>
        </div>
        {!isMobile && (
          <div styleName={`app-scan ${showMenu ? 'show-app-scan' : ''}`} className="desktop-only">
            <div styleName="app-scan-details">
              <div styleName="download-text">Download NDTV Profit</div>
              <div styleName="download-sub-text">Scan to download the app</div>
              <BQLink
                href="https://play.google.com/store/apps/details?id=com.ndtv.ndtvprofit&pcampaignid=web_share"
                target="_blank "
                rel="noopener noreferrer nofollow"
              >
                <div
                  onClick={() => downloadAppDl('play store', userData, formattedPageType, 'hamburger menu')}
                >
                  <SvgIcon
                    type="playstore"
                    width="115px"
                    height="40px"
                    source="image"
                    isSvg={false}
                    loading="lazy"
                  />
                </div>
              </BQLink>
              <BQLink
                href="https://apps.apple.com/in/app/ndtv-profit/id502359431"
                target="_blank "
                rel="noopener noreferrer nofollow"
              >
                <div
                  onClick={() => downloadAppDl('app store', userData, formattedPageType, 'hamburger menu')}
                >
                  <SvgIcon
                    type="appstore"
                    width="115px"
                    height="40px"
                    source="image"
                    isSvg={false}
                    loading="lazy"
                  />
                </div>
              </BQLink>
            </div>
            <SvgIcon
              type="app-download-scanner"
              width="172px"
              height="191px"
              source="image"
              isSvg={false}
              loading="lazy"
            />
          </div>
        )}
      </div>
      {isHomePage && showApp && (
        <BQLink
          href={appDownloadLink}
          target="_blank"
          styleName={`app-download-side-stick ${isCollapsed ? 'shrink-app-download' : ''} ${isPodcast ? 'app-up' : ''}`}
          className="mobile-only"
        >
          <CellphoneDownloadIcon size="18px" color="#fff" />
          <span style={{ color: isCollapsed ? '#3A71F5' : '#FFF' }}>GET APP</span>
        </BQLink>
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  integrations: state.qt.config['integrations'],
  clientHost: get(
    state,
    ['qt', 'config', 'publisher', 'client_host'],
    'https://www.ndtvprofit.com',
  ),
});

export default connect(mapStateToProps)(MegaMenu);
